<template>
  <div class="successHome">
    <Head :type="2"></Head>
    <Carousel></Carousel>
    <div class="successHome_con">
      <div v-for="(item, index) in successList" :key="index">
        <div :class="['list_con', (index + 1) % 3 == 0 ? 'list_con2' : '']">
          <div class="img_con">
            <img class="imgBg" :src="item.pictureAddress" alt="" />
          </div>
          <h5 class="title">{{ item.title2 }}</h5>
          <p>{{ item.description }}</p>
          <div class="chakan" @click="goDetail(item)">查看<i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next"
        :page-size="size"
        :current-page="current"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import Carousel from '@/components/Carousel.vue'
import RightSide from '@/components/RightSide.vue'
import { getSuccessPage } from '@/api/common'
export default {
  name: 'SuccessHome',
  components: {
    Head,
    Footer,
    Carousel,
    RightSide,
  },
  data() {
    return {
      current: 1,
      size: 9,
      total: 0,
      successList: [],
    }
  },
  methods: {
    goMore() {
      this.$router.push('/homeERP')
    },
    handleCurrentChange(val) {
      this.current = val
      this.getSuccessPage()
    },
    goDetail(val) {
      this.$router.push({
        path: '/successDetail',
        query: {
          index: val.id,
        },
      })
    },
    getSuccessPage() {
      let params = {
        current: this.current,
        size: this.size,
      }
      this.loading = true
      getSuccessPage(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.total
          let list = res.data.data.records
          for (let item of list) {
            if (item.title.length > 30) {
              item.title2 = item.title.substr(0, 30) + '...'
            } else {
              item.title2 = item.title
            }
          }
          this.successList = res.data.data.records
        } else {
        }
      })
    },
  },
  mounted() {
    this.getSuccessPage()
    let wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true,
      callback: function (box) {
        console.log('WOW: animating <' + box.tagName.toLowerCase() + '>')
      },
    })
    wow.init()
  },
}
</script>
<style scoped lang="less">
.successHome {
  .successHome_con {
    width: 1280px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 44px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .list_con {
    width: 410px;
    height: 600px;
    background: #ffffff;
    box-shadow: 0px 3px 32px 0px rgba(54, 54, 54, 0.15);
    margin-bottom: 40px;
    margin-right: 20px;
    padding: 25px;
    box-sizing: border-box;
    position: relative;
  }
  .list_con2 {
    margin-right: 0px;
  }
  .img_con {
    width: 100%;
    height: 270px;
    border: 1px solid #bfbfbf;
    overflow: hidden;
  }
  .title {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 40px;
    margin-top: 31px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
  }
  .chakan {
    position: absolute;
    bottom: 24px;
    width: 360px;
    height: 54px;
    border: 1px solid #008cd7;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #008cd7;
    text-align: center;
    line-height: 54px;
    cursor: pointer;
    transition: all 0.5s;
    -webkit-transition: all 0.5s; /* Safari */
  }
  .chakan:hover {
    background: #008cd7;
    color: #ffffff;
  }
  .pagination {
    width: 100%;
    text-align: center;
    margin-bottom: 44px;
  }
  .imgBg {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    transition: all 0.4s;
  }
  .imgBg:hover {
    transform: scale(1.2);
  }
}
</style>
<style lang="less">
.successHome {
  .el-pagination.is-background .btn-next {
    padding: 0px 10px;
  }
  .el-pagination.is-background .btn-prev {
    padding: 0px 10px;
  }
}
</style>
